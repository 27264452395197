<template>
  <div class="layout-default">
    <component :is="Header" />
    <main class="main layout-default__main">
      <slot />
    </main>
    <footer class="footer layout-default__footer" />
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();

    const Header = computed(() => {
      if (route.meta.headerMobile === null || route.meta.headerMobile) {
        return route.meta.headerMobile;
      }

      return defineAsyncComponent(() =>
        import("@/components/Header/HeaderMobileDefault.vue")
      );
    });

    return {
      Header,
    };
  },
};
</script>

<style scoped>
.layout-default {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}
.layout-default__main {
  width: var(--layout-default--width);
  min-height: calc(
    100vh - var(--nav-mobile-height) - var(--header-default--height)
  );

  flex-grow: 1;
  margin: 0 auto;
  padding: var(--layout-default--padding);
  padding-bottom: calc(var(--nav-mobile-height) + 30px);

  /* todo: this shit */
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
}
</style>
